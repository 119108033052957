<template>
  <main>
    <div class="container">
      <h1>ПОЛОЖЕНИЕ О ПРОВЕДЕНИИ СТИМУЛИРУЮЩЕЙ ПРОГРАММЫ ДЛЯ ДИЛЕРСКОЙ СЕТИ «АЛМАЗНЫЙ БОНУС»</h1>
      <hr>
      <p>
        Настоящая Программа предусматривает для ее Участников гарантированное вознаграждение в виде
        Универсальной подарочной карты с последующим ее обменом на любые подарочные карты и электронные
        сертификаты различных федеральных торговых сетей из каталога
        <a href="https://www.giftery.ru/" target="_blank">www.giftery.ru</a>, за выполнение
        условий Программы, изложенных
        в <a href="/Положение для дилеров Алмазный Бонус 2020.pdf" target="_blank">Положении</a>.
      </p>
      <p>
        <b>Период действия Программы</b> — с 1 ноября 2019 г. по 31 октября 2020 г.
      </p>
      <p>
        Подведение итогов Программы и начисление бонусов осуществляется <b>ежемесячно</b>.
      </p>
    </div>
  </main>
</template>

<script>
export default {
  name: 'Contacts',
  mounted: function () {
    this.$store.commit('app/updatePageClass', 'page--inner')
  }
}
</script>
